import { dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/pt";
import "dayjs/locale/pl";
import "dayjs/locale/fr";
import "dayjs/locale/nl";

dayjs.extend(localeData);

export default dayjsLocalizer(dayjs);
