import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { LoginUseCase } from "@/src/core/auth/domain/use_cases/login_use_case";
import { setLoader } from "@/src/ui/state/ui.slice";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import type AuthSliceState from "../view_models/auth.slice";
import type LoginFormData from "../pages/auth/view_models/login_form_data";
import { LocalStorageKey } from "../view_models/local_storage";
import type { MeUseCase } from "@/src/core/auth/domain/use_cases/me_use_case";
import type { RootState } from ".";
import type { MeResponse } from "@/src/core/auth/data/interfaces/me";

const initialState = (): AuthSliceState => ({
  isLogged: false,
  user: null,
});

export const meThunk = createAsyncThunk<MeResponse, void, { state: RootState }>(
  "auth.slice/me",
  async (payload, { dispatch }) => {
    dispatch(setLoader(true));

    try {
      const useCase = await locator.get<IocProvider<MeUseCase>>(
        TYPES.MeUseCase
      )();
      const response = await useCase.execute();

      localStorage.setItem(LocalStorageKey.User, JSON.stringify(response));

      return response;
    } finally {
      dispatch(setLoader(false));
    }
  }
);

export const loginThunk = createAsyncThunk<
  void,
  LoginFormData,
  { state: RootState }
>("auth.slice/login", async (payload, { dispatch }) => {
  dispatch(setLoader(true));

  try {
    const useCase = await locator.get<IocProvider<LoginUseCase>>(
      TYPES.LoginUseCase
    )();
    const accessToken = await useCase.execute(payload);

    localStorage.setItem(LocalStorageKey.AccessToken, accessToken);

    await dispatch(meThunk());
  } finally {
    dispatch(setLoader(false));
  }
});

export const logoutThunk = createAsyncThunk(
  "auth.slice/logout",
  async (payload, { dispatch }) => {
    dispatch(setLoader(true));

    try {
      localStorage.removeItem(LocalStorageKey.AccessToken);
      localStorage.removeItem(LocalStorageKey.User);
    } finally {
      dispatch(setLoader(false));
    }
  }
);

const authSlice = createSlice({
  name: "auth.slice",
  initialState: initialState(),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(meThunk.fulfilled, (state, response) => {
      state.user = response.payload;
    });

    builder.addCase(loginThunk.fulfilled, (state) => {
      state.isLogged = true;
    });

    builder.addCase(logoutThunk.fulfilled, (state) => {
      state.isLogged = false;
    });
  },
});

function selectBase(state: RootState) {
  return state.auth;
}

const isAustralianClinic = createSelector(
  [selectBase],
  (slice) => slice.user?.country_id === 13
);

export const authSelectors = {
  isAustralianClinic,
};
export const authActions = authSlice.actions;
export default authSlice.reducer;
