import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import i18next from "i18next";
import { LocalStorageKey } from "../view_models/local_storage";
import { Language } from "../pages/misc/view_models/config";
import dayjs from "dayjs";
import "@/src/common/utils/dayjs_big_calendar_localizer";

i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./locales/${language}/${namespace}.json`)
        .then(({ default: resources }) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .use(initReactI18next)
  .init({
    defaultNS: "misc",
    lng: Language.Spanish,
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  })
  .then(() => {
    const language = localStorage.getItem(LocalStorageKey.Language);
    const navigatorLanguage = Object.values(Language).includes(
      window.navigator.language.split("-")[0] as Language
    )
      ? (window.navigator.language.split("-")[0] as Language)
      : Language.Spanish;

    i18next.changeLanguage(language || navigatorLanguage);

    dayjs.locale(language || navigatorLanguage);
  });

i18n.on("languageChanged", (language) => {
  dayjs.locale(language);
});

export default i18n;
